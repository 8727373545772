<template>
  <div>
    <div>
      <div
        class="d-flex justify-space-between px-10"
        style="background-color:#F8F8F8"
      >
        <div>
          <h1 class="my-4 page-title-list" style="color:#000000 !important">
            顧客/会員
          </h1>
        </div>
        <div>
          <v-btn :disabled="!checkPerUser" class="t-btn--prm mt-3 button-header" @click="visible = 1">
            新規顧客作成
          </v-btn>
        </div>
      </div>
      <template>
        <v-tabs class="mb-2" v-model="tab" align-with-title>
          <v-tabs-slider color="#13ace0"></v-tabs-slider>
          <!-- class="page-title-tab" -->
          <!-- :to="item.to" -->
          <v-tab v-for="item in items" :key="item.id" :to="item.to" exact
            >{{ item.title }}
          </v-tab>
        </v-tabs>
      </template>
      <!-- <template>
        <div class="d-flex justify-start mb-2">
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="#13ACE0"></v-tabs-slider>
            <v-tab
              exact
              class="page-title-tab"
              v-for="(item, i) in items"
              :key="i"
              :to="item.to"
              >{{ item.title }}
            </v-tab>
          </v-tabs>
        </div>
      </template> -->
      <slot name="content" />
    </div>
    <create-new-customer
      :visible="visible === 1"
      @close="visible = -1"
    ></create-new-customer>
  </div>
  <!-- Dialog -->
</template>
<script>
import CreateNewCustomer from './CreateNewCustomer.vue';
import { checkPermissionUserCurrent, checkPermissionTab } from '@/utils/permissions'

export default {
  name: 'CustomerMemberTab',
  data() {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      visible: -1,
      // Tabs
      tab: null,
      items: [],
      itemsTmp: [
        {
          id: 1,
          title: '全ての顧客',
          to: '/customer',
          pathRoot: '/customer',
          order: 1
        },
        {
          id: 2,
          title: '見込客リスト',
          to: '/customer/prospect-list',
          pathRoot: '/customer/prospect-list',
          order: 2
        },
        {
          id: 3,
          title: '会員リスト',
          to: '/customer/member-list',
          pathRoot: '/customer/member-list',
          order: 3
        },
      ],
    };
  },
  components: { CreateNewCustomer },
  mounted () {
    switch (this.$route.path) {
      case "/customer/prospect-list":
        this.itemsTmp = [
          {
            id: 1,
            title: '全ての顧客',
            to: '/customer',
            pathRoot: '/customer',
            order: 1
          },
          {
            id: 2,
            title: '見込客リスト',
            to: '/customer/prospect-list',
            pathRoot: '/customer/prospect-list',
            order: 2
          },
          {
            id: 3,
            title: '会員リスト',
            to: '/customer/member-list',
            pathRoot: '/customer/member-list',
            order: 3
          }
        ]
        break;
      case "/customer/prospect-list/experience-based-accommodation-customers":
        this.itemsTmp = [
          {
            id: 1,
            title: '全ての顧客',
            to: '/customer',
            pathRoot: '/customer',
            order: 1
          },
          {
            id: 2,
            title: '見込客リスト',
            to: '/customer/prospect-list/experience-based-accommodation-customers',
            pathRoot: '/customer/prospect-list/experience-based-accommodation-customers',
            order: 2
          },
          {
            id: 3,
            title: '会員リスト',
            to: '/customer/member-list',
            pathRoot: '/customer/member-list',
            order: 3
          }
        ]
        break;
      case "/customer/prospect-list/face-to-face-customer":
        this.itemsTmp = [
          {
            id: 1,
            title: '全ての顧客',
            to: '/customer',
            pathRoot: '/customer',
            order: 1
          },
          {
            id: 2,
            title: '見込客リスト',
            to: '/customer/prospect-list/face-to-face-customer',
            pathRoot: '/customer/prospect-list/face-to-face-customer',
            order: 2
          },
          {
            id: 3,
            title: '会員リスト',
            to: '/customer/member-list',
            pathRoot: '/customer/member-list',
            order: 3
          }
        ]
        break;
    
      default:
        break;
    }
    this.checkPerTab()
  },
  methods: {
    checkPermissionTab,
    checkPerTab () {
      let itemCheck = this.checkPermissionTab(this.itemsTmp)
      itemCheck = [...new Set(itemCheck)]
      itemCheck.sort((a, b) => a.order - b.order)
      this.items = itemCheck
    }
  }
};
</script>

<style lang="scss" scope>
.v-tab:not(.v-tab--active) {
  background-color: #ffffff;
  color: #aaaaaa !important;
  &:hover {
    background-color: #eeeeee;
    color: #13ace0 !important;
  }
}
.v-tab--active {
  background-color: #ffffff !important;
  color: #13ace0 !important;
}

.v-tab--active:hover {
  background-color: #ceeffb !important;
  color: #13ace0 !important;
}
.color-title {
  background-color: #f8f8f8 !important;
}

.button-header {
  span {
    color: #ffffff !important;
  }
}
</style>
