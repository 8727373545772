export const checkNumber = val => {
  const regex = /^[0-9]+$/;
  return regex.test(val);
};

export const isEmailValid = email => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
}

export const checkMoney = val => {
  const regex = /^[0-9]+$/;
  if(val.substring(0,1) === '¥'){
    val = val.substring(1)
  }
  return regex.test(val);
};

export function checkValidatePassword (v) {
  let count = 0
  // nhap so
  const regex1 = /^(?=.*\d)/
  // ky tu thuong
  const regex2 = /(?=.*[a-z])/
  // ky tu hoa
  const regex3 = /(?=.*[A-Z])/
  // ky tu dac biet
  const regex4 = /(?=.*[!@#$%^&*])/
  if (regex1.test(v)) {
    count = count + 1
  }
  if (regex2.test(v)) {
    count = count + 1
  }
  if (regex3.test(v)) {
    count = count + 1
  }
  if (regex4.test(v)) {
    count = count + 1
  }
  if (count >= 2 && v.length >= 8) {
    return true
  } else return false
}

export const validDatePickerRange = (value) => {
  if(value){
    if(value && value.split("~").length !== 2){
      return false
    }else{
      return true
    }
  }else{
    return false
  }
}