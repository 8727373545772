<template>
  <main-layout>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog v-model="dialog" content-class="dialog-cus">
        <h1 class="mb-3" style="font-size: 28px; color: #212121">
          顧客新規作成
        </h1>
        <!-- Select isCompany -->
        <v-row>
          <v-col cols="6">
            <h6 class="label-title">
              {{ $t('customerCreate.Individual') }}/{{
                $t('customerCreate.Corporation')
              }}
            </h6>
            <v-select
              :items="itemsData.isCompany"
              item-value="id"
              item-text="name"
              @change="resetForm"
              v-model="finishData.isCompany"
              placeholder="個人"
              class="text-select"
            ></v-select>
          </v-col>
        </v-row>
        <!-- End Select isCompany -->

        <!-- Content -->
        <v-row class="d-flex justify-space-between" v-if="finishData.isCompany">
          <v-col cols="12">
            <h6 class="label-title">社名</h6>
              <!-- @input="change_name" -->
            <v-text-field
              v-model="finishData.companyName"
              :rules="[$rules.required, $rules.checkLenghInput(255)]"
              class="text-field"
              @change="checkPossiblyDuplicatedClientList"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-space-between" v-else>
          <v-col cols="6">
            <h6 class="label-title">
              {{ $t('customerCreate.CustomerName') }}（{{
                $t('customerCreate.Surname')
              }})
            </h6>
              <!-- @input="change_name" -->
            <v-text-field
              v-model="finishData.lastName"
              :rules="[$rules.required, $rules.checkLenghInput(255)]"
              class="text-field"
              @change="checkPossiblyDuplicatedClientList"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <h6 class="label-title">
              {{ $t('customerCreate.CustomerName') }}（{{
                $t('customerCreate.Name')
              }}）
            </h6>
              <!-- @input="change_name" -->
            <v-text-field
              v-model="finishData.firstName"
              :rules="[$rules.required, $rules.checkLenghInput(255)]"
              class="text-field"
              @change="checkPossiblyDuplicatedClientList"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-space-between" v-if="finishData.isCompany">
          <v-col cols="12">
            <h6 class="label-title">フリガナ</h6>
              <!-- @input="change_name" -->
            <v-text-field
              v-model="finishData.companyNameKana"
              :rules="[$rules.checkKatakana, $rules.required, $rules.checkLenghInput(255)]"
              class="text-field"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-space-between" v-else>
          <v-col cols="6">
            <h6 class="label-title">
              {{ $t('customerCreate.Furigana') }}（{{
                $t('customerCreate.Surname')
              }})
            </h6>
            <v-text-field
              v-model="finishData.lastKana"
              style="width: 270px"
              :rules="[$rules.checkKatakana, $rules.required, $rules.checkLenghInput(255)]"
              class="text-field"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <h6 class="label-title">
              {{ $t('customerCreate.Furigana') }}（{{
                $t('customerCreate.Name')
              }}）
            </h6>
            <v-text-field
              v-model="finishData.firstKana"
              style="width: 270px"
              :rules="[$rules.checkKatakana, $rules.required, $rules.checkLenghInput(255)]"
              class="text-field"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-space-between">
          <v-col cols="7">
            <v-text-field
              ref="postalCode"
              placeholder="会社郵便番号"
              v-model="finishData.postalCode"
              v-if="finishData.isCompany"
              :rules="[$rules.postalCodeNoHyphen]"
              class="text-field"
              @change="checkPossiblyDuplicatedClientList"
            ></v-text-field>
            <v-text-field
              ref="postalCode"
              placeholder="自宅郵便番号"
              v-model="finishData.postalCode"
              v-else
              :rules="[$rules.postalCodeNoHyphen]"
              class="text-field"
              @change="checkPossiblyDuplicatedClientList"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              ref="tel"
              v-model="finishData.tel"
              placeholder="会社電話番号"
              v-if="finishData.isCompany"
              :rules="[$rules.checkLenghInput(255)]"
              class="text-field"
              @change="checkPossiblyDuplicatedClientList"
            ></v-text-field>
            <v-text-field
              ref="tel"
              v-model="finishData.tel"
              placeholder="自宅電話番号"
              v-else
              :rules="[$rules.checkLenghInput(255)]"
              class="text-field"
              @change="checkPossiblyDuplicatedClientList"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row
          class="d-flex justify-space-between"
          v-if="finishData.isCompany === false || finishData.isCompany === null"
        >
          <v-col cols="4">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  ref="date"
                  v-model="finishData.birthday"
                  label="生年月日"
                  readonly
                  v-bind="attrs"
                  :rules="rules.check_date_exist"
                  v-on="on"
                  class="text-field"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="finishData.birthday"
                :active-picker.sync="activePicker"
                :max="
                  new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                    .toISOString()
                    .substr(0, 10)
                "
                min="1900-01-01"
                :locale="$i18n.locale"
                @change="saveBirthDate"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <!-- End Content -->

        <!-- Button -->
        <div class="d-flex flex-row-reverse mt-2">
          <div class="d-flex">
            <v-btn
              large
              class="t-btn--red-dark button-cancel"
              @click="closeDialog"
            >
              <v-icon class="mr-2" size="22">mdi-close</v-icon>
              {{ $t('customerCreate.close') }}
            </v-btn>
            <v-btn large class="t-btn--prm ml-3 button-create" @click="save">
              {{ $t('customerCreate.save') }}
            </v-btn>
          </div>
        </div>
        <p class="dupplicated-style" v-if="isDupplicated === true">重複顧客である可能性があります</p>
      </v-dialog>
    </v-form>
  </main-layout>
</template>

<script>
import MainLayout from '@/layouts/MainLayout';
import { mapMutations, mapActions } from 'vuex';
import { checkNumber } from '@/utils/validate';

export default {
  name: 'CreateNewCustomer',
  components: { MainLayout },
  data() {
    return {
      // check: false,
      valid: true,
      activePicker: null,
      menu: false,
      rules: {
        // textRules: [
        //   (v) => !!v || '必須項目です',
        //   (v) => (v && (CheckLenghInput(v, 255))) || !v || this.$t('rules.maximumNCharacter', { value: 255 })
        // ],
        check_postalCode_exist: [
          // (v) => !!v || '必須項目です',
          // (v) =>
          //   this.func_check_postalCode_exist(v) || 'すでに存在しています',
          (v) => (v && checkNumber(v)) || !v || this.$t('rules.numberInvalid'),
          (v) =>
            (v && v.length === 7) || !v ||
            this.$t('rules.includeNCharacters', { value: 7 }),
        ],
        // check_tel_exist: [
        //   // (v) => !!v || '必須項目です',
        //   // (v) => this.func_check_tel_exist(v) || 'すでに存在しています',
        //   (v) => (v && (CheckLenghInput(v, 255))) || !v || this.$t('rules.maximumNCharacter', { value: 255 })
        // ],
        check_date_exist: [
          // (v) => !!v || '必須項目です',
          // (v) => this.func_check_date_exist(v) || !v || 'すでに存在しています',
        ],
      },
      itemsData: {
        isCompany: [
          { id: false, name: '個人' },
          { id: true, name: '法人' },
        ],
        // 法人 isCompany = true
      },
      finishData: {
        // 個人 isCompany = false
        isCompany: false,
        lastName: null,
        firstName: null,
        lastKana: null,
        firstKana: null,
        postalCode: null,
        tel: null,
        birthday: null,

        // 法人 isCompany = true
        companyName: null,
        companyNameKana: null,
      },
      isDupplicated: false,
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'));
    },
  },
  props: {
    visible: Boolean,
  },

  mounted() {
  },

  computed: {
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.closeDialog();
        }
      },
    },
  },
  methods: {
    ...mapActions([
      'actionCreateClient',
      'actionPossiblyDuplicatedClientList'
    ]),
    ...mapMutations([
      // Alert
      'setAlertSuccess',
      'setAlertError',
      'setPermissionUser',
      'setRoleAdminUser'
    ]),

    checkPossiblyDuplicatedClientList () {
      const finishData = {
        isCompany: this.finishData.isCompany,
        firstName: this.finishData.firstName,
        lastName: this.finishData.lastName,
        companyName: this.finishData.companyName,
        tel: this.finishData.tel,
        birthday: this.finishData.birthday,
        postalCode: this.finishData.postalCode
      }
      if (this.finishData.postalCode === '') {
        finishData.postalCode = null;
      }
      if (this.finishData.isCompany) {
        delete finishData.lastName;
        delete finishData.firstName;
        delete finishData.birthday;
      } else {
        delete finishData.companyName;
      }
      this.actionPossiblyDuplicatedClientList(finishData).then(result => {
        if (result.length > 0) {
          this.isDupplicated = true
        } else {
          this.isDupplicated = false
        }
      })
    },

    resetForm (value){
      this.$refs.form.resetValidation() 
      this.finishData =  {
        // 個人 isCompany = false
        isCompany: value,
        lastName: null,
        firstName: null,
        lastKana: null,
        firstKana: null,
        postalCode: null,
        tel: null,
        birthday: null,

        // 法人 isCompany = true
        companyName: null,
        companyNameKana: null,
      }
    },
    
    save() {
      if (this.$refs.form.validate()) {
        if (this.valid) {
          const newFinishData = {
            ...this.finishData,
          };
          if (newFinishData.postalCode === '') {
            newFinishData.postalCode = null;
          }
          if (this.finishData.isCompany) {
            delete newFinishData.lastName;
            delete newFinishData.firstName;
            delete newFinishData.lastKana;
            delete newFinishData.firstKana;
            delete newFinishData.birthday;
          } else {
            delete newFinishData.companyName;
            delete newFinishData.companyNameKana;
          }
          this.actionCreateClient(newFinishData).then((createClient) => {
            window.open(`/customer-details/prospect-information/${createClient.id}?typeClient=${createClient.type}`)
            this.closeDialog();
          });
        }
      }
    },

    closeDialog() {
      this.$emit('close');
      this.$refs.form.resetValidation();
      this.finishData =  {
        // 個人 isCompany = false
        isCompany: false,
        lastName: null,
        firstName: null,
        lastKana: null,
        firstKana: null,
        postalCode: null,
        tel: null,
        birthday: null,

        // 法人 isCompany = true
        companyName: null,
        companyNameKana: null,
      }
    },

    saveBirthDate(date) {
      this.$refs.menu.save(date);
      this.checkPossiblyDuplicatedClientList()
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  overflow: hidden;
}
.label-title {
  font-size: 10px;
  color: #000000;
  font-weight: 600;
}
::v-deep {
  .v-dialog.dialog-cus {
    width: 470px;
    background-color: white;
    padding: 14px 26px;
    border-radius: 9px;
    font-size: 14px;
    .v-label {
      font-size: 14px;
    }
    .v-text-field__details {
      min-height: auto !important;
      .v-messages {
        min-height: auto !important;
        .v-messages__wrapper:empty {
          display: none;
        }
      }
    }
    .v-text-field {
      padding-top: 0;
      margin-top: 0;
    }
  }
  .w-50 {
    width: 50%;
  }
  .v-counter {
    font-size: 10px;
    color: var(--text__dark) !important;
    font-weight: 600;
  }
  .sort-item {
    max-width: 100px;
  }
  .cancel-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .v-input {
      max-width: 40px;
      padding: 0;
      * {
        text-align: center;
      }
    }
  }
}
.text-select {
  ::v-deep {
    .v-select__selections {
      input::placeholder {
        color: #444444 !important;
        font-size: 14px;
        font-weight: 500;
      }
    }
    .v-select__selection--comma {
      color: #000000 !important;
      font-size: 14px;
      font-weight: 500;
    }
    .v-icon {
      color: #333333;
    }
  }
}
.text-field {
  ::v-deep {
    .v-text-field__slot {
      label {
        color: #444444;
        font-size: 14px;
        font-weight: 500;
      }
      input {
        color: #000000;
        font-size: 14px;
        font-weight: 500;
      }
      input::placeholder {
        color: #444444;
        font-size: 14px;
        font-weight: 500;
      }
    }
    .v-label {
      color: #444444 !important;
    }
  }
}
.button-cancel {
  width: 90px;
  height: 36px;
  ::v-deep {
    .v-btn__content {
      font-size: 14px !important;
    }
  }
}
.button-create {
  width: 72px;
  height: 36px;
  ::v-deep {
    .v-btn__content {
      font-size: 14px !important;
    }
  }
}
.dupplicated-style {
  color: red;
}
</style>